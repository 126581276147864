import React, { useEffect, useState } from "react";
import DataList from "Components/Common/DataList/DataList";
import LineMenu from "Components/Common/LineMenu/LineMenu";
import { ConsultingMenuData } from "Components/Data/Consulting/ConsultingMenuData";
// import { ConsultingDataBase } from "Components/Data/Consulting/ConsultingDataBase";
import { Link } from "react-router-dom";
import { RoutesString } from "Components/Module/API/RoutesString";

import Contact from "Assets/Images/Common/contact-icon.svg";
import "./Consulting.scss";

function Consulting() {
	const [selectTag, setSelectTag] = useState("ALL");
	const inputChange = (value: string) => {
		setSelectTag(value);
	};
	return (
		<div className="consulting-container">
			<div className="consulting-wrapper">
				<div className="consulting-intro-box">
					<li className="left-box">
						<span className="title">CONSULTING</span>
					</li>
					<li className="right-box">
						<span className="description">
							살으리의 외부 프로젝트는 고객이 생각하는
							<br />
							공간 인테리어 디자인을 최대한 끌어내며
							<br />
							살으리만의 색깔로 모든 인테리어 공간의 벨런스를 잡아줍니다.
						</span>
					</li>
				</div>
				<div className="Consulting-menu">
					<LineMenu
						list={ConsultingMenuData}
						selectTag={selectTag}
						onChangeInput={inputChange}
					/>
				</div>
				<div className="contact-consulting-box">
					<div className="contact-icon">
						<img src={Contact} alt="" />
					</div>
					<div className="consulting-text">
						<span>살으리는 여러분과의 작업을 기다리고있습니다.</span>
						<Link to={RoutesString.Contact}>
							<span>CONTACT</span>
						</Link>
					</div>
				</div>
				{/* <div className="Consulting-data-list">
					<DataList list={ConsultingDataBase} selectTag={selectTag} />
				</div> */}
			</div>
		</div>
	);
}

export default Consulting;
