import React from "react";
import { Link } from "react-router-dom";
import PlusIcon from "Assets/Images/Common/plus-icon.png";
import "./SubLine.scss";
interface ISubLine {
	title: string;
	link?: string;
}

function SubLine(props: ISubLine) {
	const { title, link } = props;
	return (
		<div className="sub-line-container">
			<div className="sub-line-wrapper">
				<div className="sub-line-title">{title}</div>
				{link !== undefined && (
					<Link to={link}>
						<div className="sub-line-more">
							<span>more</span>
						</div>
						<div className="sub-line-icon">
							<img src={PlusIcon} alt="" />
						</div>
					</Link>
				)}
			</div>
		</div>
	);
}

export default SubLine;
