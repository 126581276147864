import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "Assets/Styles/Fontface.scss";
import "Assets/Styles/Reset.scss";

import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "Components/Common/ScrollToTap/ScrollToTap";

// i18n 사용시 주석 해제
// import { I18nextProvider } from "react-i18next";
// import i18n from "Components/Module/i18n/locales";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<React.StrictMode>
		{/* i18n 사용시 주석 해제 */}
		{/* <I18nextProvider i18n={i18n}> */}
		<BrowserRouter basename="">
			<ScrollToTop />
			<App />
		</BrowserRouter>
		{/* </I18nextProvider> */}
	</React.StrictMode>
);
