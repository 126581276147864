import { IAboutData } from "Components/Module/API/Interface/Data.interface";

export const AboutPhilosophyDataBase: IAboutData[] = [
	{
		id: "about-data-0-0",
		title: "고급스러운 공간",
		description:
			"우리가 만드는 고급스러운 공간은 언제나\n 당신에게 멋지고 편안한 생활 경험을 선사합니다."
	},

	{
		id: "about-data-0-1",
		title: "세련된 인테리어",
		description:
			"완벽한 생활 공간은 세련된 인테리어로 만들어집니다.\n살으리와 함께라면 생활공간, 업무공간을 바라보는 것 만으로도\n 창의적인 아이디어가 생길 수 있습니다."
	},
	{
		id: "about-data-0-2",
		title: "높은 직업 정신",
		description:
			"높은 퀄리티를 위해 충분한 시간을\n가지고 한번 더 고민하고 \n발전시켜 나아갑니다."
	},
	{
		id: "about-data-0-3",
		title: "휴식의 공간",
		description:
			"살으리는 지친 하루를 위로해 드립니다.\n평화로운 분위기의 생활 공간으로 들어가\n당신만을 위한 편안함을 느껴보세요."
	}
];

export const AboutDirectionalDataBase: IAboutData[] = [
	{
		id: "about-data-1-0",
		title: "다양성",
		description:
			"살으리의 인테리어는 당신의 공간에 원하는 모든 디자인 스타일에 맞는 새롭고 트렌디하고 적합한 디자인으로 항상 다양합니다."
	},

	{
		id: "about-data-1-1",
		title: "비즈니스",
		description:
			"외부업체의 경우 항상 파트너의 요구 사항을 깊이 있고 철저하게 이해하고 파트너가 원하는 방향으로 비즈니스를 발전시킬 수 있도록 철저한 컨설팅을 제공합니다."
	},
	{
		id: "about-data-1-2",
		title: "인테리어",
		description: "시간이 지나도 높은 퀄리티와 세련된 공간을 유지하는 인테리어를 유지합니다."
	},
	{
		id: "about-data-1-3",
		title: "높은품질",
		description:
			"살으리의 인테리어는 틈새의 모든 공간을 최적화할 뿐만 아니라 10년, 20년이 지나도 최상의 품질을 보장합니다."
	}
];
