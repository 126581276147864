import React from "react";
import "./PageNotFound.scss";

function PageNotFound() {
	return (
		<>
			<div className="not-found-container">
				<span>ERROR : 404</span>
				<span>페이지를 찾을수가 없습니다.</span>
			</div>
		</>
	);
}
export default PageNotFound;
