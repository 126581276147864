import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PageNotFound from "Components/Pages/Error/PageNotFound";
import Header from "Components/Common/Header/Header";
import Footer from "Components/Common/Footer/Footer";
import { RoutesString } from "Components/Module/API/RoutesString";
import Home from "Components/Pages/Home/Home";
import About from "Components/Pages/About/About";
import Consulting from "Components/Pages/Consulting/Consulting";
import Project from "Components/Pages/Project/Project";
import Contact from "Components/Pages/Contact/Contact";
import "./App.scss";

function App() {
	const location = useLocation();

	useEffect(() => {
		// i18n 사용시 주석 해제
		// const language = sessionStorage.getItem("language");
		// if (language) {
		// 	i18n.changeLanguage(language);
		// }
	}, []);

	return (
		<div className="app-root">
			<div className="app-root-wrapper">
				<div className=".app-root-header">
					<Header />
				</div>
				<Routes>
					<Route path={RoutesString.Home} element={<Home />} />
					<Route path={RoutesString.About} element={<About />} />
					<Route path={RoutesString.Consulting} element={<Consulting />} />
					<Route path={RoutesString.Project} element={<Project />} />
					<Route path={RoutesString.Contact} element={<Contact />} />
					<Route path="*" element={<PageNotFound />} />
				</Routes>
				<Footer />
			</div>
		</div>
	);
}

export default App;
