import React from "react";
import { IProjectData } from "Components/Module/API/Interface/Data.interface";
import useMoveScroll from "Components/Module/Helper/useMoveScroll";
import exterior from "Assets/Images/Common/exterior-icon.svg";
import interior from "Assets/Images/Common/interior-icon.svg";
import td from "Assets/Images/Common/td-icon.svg";
import floor from "Assets/Images/Common/floor-icon.svg";
import close from "Assets/Images/Common/close-icon.svg";
import "./DataDialog.scss";

interface IDataDialog {
	data: IProjectData;
	onClickCloseDialog(): void;
}

function DataDialog(props: IDataDialog) {
	const { data, onClickCloseDialog } = props;

	const menuTap = [
		{
			id: "exterior",
			title: "Exterior",
			img: exterior,
			toScroll: useMoveScroll(),
			length: data.exteriorImg.length
		},
		{
			id: "interior",
			title: "Interior",
			img: interior,
			toScroll: useMoveScroll(),
			length: data.interiorImg.length
		},
		{
			id: "3D",
			title: "3D",
			img: td,
			toScroll: useMoveScroll(),
			length: data.threeDimensionalImg.length
		},
		{
			id: "floor",
			title: "Floor",
			img: floor,
			toScroll: useMoveScroll(),
			length: data.floorPlanImg.length
		}
	];

	return (
		<div className=" data-dialog-container" onClick={onClickCloseDialog}>
			<div className="data-dialog-wrapper">
				<div
					className="dialog-box"
					onClick={event => {
						event.stopPropagation();
					}}
				>
					<div className="dialog-menu-box">
						{menuTap.map((value, index) => {
							return (
								<>
									{value.length !== 0 && (
										<div
											key={value.id + "-" + index}
											className="menu-btn"
											onClick={value.toScroll?.onMoveToElement}
										>
											<div className="icon-box">
												<img src={value.img} alt="" />
											</div>
											<span>{value.title}</span>
										</div>
									)}
								</>
							);
						})}
						{/* <div className="menu-btn" onClick={menuTap[0][0].}>
							<div className="icon-box">
								<img src={exterior} alt="" />
							</div>
							<span>Exterior</span>
						</div>
						<div className="menu-btn">
							<div className="icon-box">
								<img src={interior} alt="" />
							</div>
							<span>Interior</span>
						</div>
						<div className="menu-btn">
							<div className="icon-box">
								<img src={model} alt="" />
							</div>
							<span>3D</span>
						</div>
						<div className="menu-btn">
							<div className="icon-box">
								<img src={floor} alt="" />
							</div>
							<span>Floor</span>
						</div> */}
					</div>

					<div className="dialog-page">
						<ul className="header-box">
							<li className="header">
								<span>{data.title}</span>
							</li>
							<li className="close-btn" onClick={onClickCloseDialog}>
								<img src={close} alt="" />
							</li>
						</ul>
						<ul className="contants-box">
							{data.exteriorImg.length !== 0 && (
								<div
									className="contants-list-box"
									ref={menuTap[0].toScroll.element}
								>
									<div className="contants-list-title">
										<span>Exterior</span>
									</div>
									<div className="contants-list-list">
										{data.exteriorImg.map((value, index) => {
											return (
												<div
													key={data.id + "-" + "exterior" + "-" + index}
													className="list-img"
												>
													<img src={value} alt="" />
												</div>
											);
										})}
									</div>
								</div>
							)}
							{data.interiorImg.length !== 0 && (
								<div
									className="contants-list-box"
									ref={menuTap[1].toScroll.element}
								>
									<div className="contants-list-title">
										<span>Interior</span>
									</div>
									<div className="contants-list-list">
										{data.interiorImg.map((value, index) => {
											return (
												<div
													key={data.id + "-" + "interior" + "-" + index}
													className="list-img"
												>
													<img src={value} alt="" />
												</div>
											);
										})}
									</div>
								</div>
							)}
							{data.threeDimensionalImg.length !== 0 && (
								<div
									className="contants-list-box"
									ref={menuTap[2].toScroll.element}
								>
									<div className="contants-list-title">
										<span>3D</span>
									</div>
									<div className="contants-list-list">
										{data.threeDimensionalImg.map((value, index) => {
											return (
												<div
													key={data.id + "-" + "interior" + "-" + index}
													className="list-img"
												>
													<img src={value} alt="" />
												</div>
											);
										})}
									</div>
								</div>
							)}
							{data.floorPlanImg.length !== 0 && (
								<div
									className="contants-list-box"
									ref={menuTap[3].toScroll.element}
								>
									<div className="contants-list-title">
										<span>Floor Plan</span>
									</div>
									<div className="contants-list-list">
										{data.floorPlanImg.map((value, index) => {
											return (
												<div
													key={data.id + "-" + "interior" + "-" + index}
													className="list-img"
												>
													<img src={value} alt="" />
												</div>
											);
										})}
									</div>
								</div>
							)}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DataDialog;
