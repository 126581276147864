import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ISliderData {
	list?: string[];
	isTop: boolean;
}

function HomeSlider(props: ISliderData) {
	const sliderRef = React.useRef<Slider | null>(null);
	const { list, isTop } = props;
	const settings = {
		dots: false,
		arrows: false,
		fade: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		pauseOnHover: false,
		autoplaySpeed: 4000
	};

	setTimeout(() => {
		if (isTop === false) {
			sliderRef.current?.slickPlay();
		}
	}, 2500);
	useEffect(() => {
		sliderRef.current?.slickPause();
	}, []);
	return (
		<div className="homeslider-container">
			<Slider ref={sliderRef} {...settings}>
				{list?.map((value, index) => {
					return (
						<div key={value + "index"} className="slider-box">
							<img src={value} alt="" />
						</div>
					);
				})}
			</Slider>
		</div>
	);
}
export default HomeSlider;
