import React, { useEffect, useState } from "react";
import "./Home.scss";
import Background from "Assets/Images/Home/background.png";
import SubLine from "Components/Common/SubLine/SubLine";
import { RoutesString } from "Components/Module/API/RoutesString";
import { ProjectDataBase } from "Components/Data/Project/ProjectDataBase";
import { IProjectData } from "Components/Module/API/Interface/Data.interface";
import { HomeBottomDataBase, HomeBusinessDataBase } from "Components/Data/Home/HomeDataBase";

import BottomBanner from "Components/Common/BottomBanner/BottomBanner";
import HomeSlider from "./HomeSlider/HomeSlider";

function Home() {
	const [topProject, setTopProject] = useState<IProjectData>();
	const [bottomProject, setBottomProject] = useState<IProjectData>();
	useEffect(() => {
		ProjectDataBase.map((value, index) => {
			if (value.id === "ShinSaOffice") {
				setTopProject(value);
			}
			if (value.id === "NomadCafe") {
				setBottomProject(value);
			}
		});
	});

	return (
		<>
			<div className="home-container">
				<div className="home-wrapper">
					{/* Top Banner */}
					<ul className="home-banner-box">
						<li className="banner-top">
							<span className="top-left-box">
								Saluli, design a space
								<br />
								where people live.
							</span>
							<span className="top-right-box">
								살으리는 우리가 살아가는 공간이 더 아름답고 편안한 안식처가 되길
								바랍니다.
								<br />
								<br />
								사람들은 항상 삶의 질을 최우선으로 생각합니다.
								<br />
								생활 공간에서 인테리어는 사용자의 편안함을 만드는 데 큰 역할을
								합니다.
								<br />
								<br />
								사람이 살아가는, 공간을 설계하는 살으리
								<br />
								살으리는 고객의 생활공간과 업무공간을 위한 서비스를 제공합니다.
							</span>
						</li>
						<li className="banner-center">
							<div className="center-banner-box">
								<img src={Background} alt="" />
							</div>
						</li>
						<li className="banner-bottom">
							<span className="bottom-left-box">
								Saluli
								<br />
								Interior
							</span>
							<span className="bottom-right-box">
								인테리어는 건축공간의 영혼입니다.
								<br />
								<br />
								살으리의 인테리어는 삶의 질을 높이고 사용자의 개성을 강조할 수
								있도록 디자인 됩니다.
								<br />
								인테리어의 색상은 공간과 라이프 스타일에 적합하도록 설계되며,
								<br />
								건축공간의 모든 틈새를 활용하여 공간을 최적화합니다.
							</span>
						</li>
					</ul>
					{/* Project Banner */}
					<ul className="home-project-box">
						<SubLine title="Saluli PROJECT" link={RoutesString.Project} />
						<li className="project-box">
							<ol className="left-box">
								<li className="top-left-title">
									<span>{topProject?.title}</span>
								</li>
								<li className="top-left-description">
									<span>{topProject?.description}</span>
								</li>
							</ol>
							<ol className="right-box">
								<HomeSlider list={topProject?.mainBanner} isTop={true} />
							</ol>
						</li>
						<SubLine title="Saluli PROJECT" link={RoutesString.Project} />
						<li className="project-box">
							<ol className="left-box">
								<li className="top-left-title">
									<span>{bottomProject?.title}</span>
								</li>
								<li className="top-left-description">
									<span>{bottomProject?.description}</span>
								</li>
							</ol>
							<ol className="right-box">
								<HomeSlider list={bottomProject?.mainBanner} isTop={false} />
							</ol>
						</li>
					</ul>

					{/* AboutBanner */}
					<ul className="home-about-box">
						<SubLine title="Saluli BUSINESS" link={RoutesString.About} />
						<li className="about-title">
							<span>
								The Super Exports Have
								<br />
								All Joined The Team
							</span>
						</li>
						<li className="about-list">
							{HomeBusinessDataBase.map((value, index) => {
								return (
									<div className="list-box-container" key={index}>
										<div className="list-box-wrapper">
											<div className="list-box-title">
												<span>{value.title}</span>
											</div>
											<div className="list-box-description">
												<span>{value.description}</span>
											</div>
										</div>
									</div>
								);
							})}
						</li>
					</ul>

					<ul className="home-bottom-box">
						<li className="title-box">
							<span className="title">
								Saluli’s
								<br />
								philosophy of construction
							</span>
						</li>
						<li className="list-box">
							{HomeBottomDataBase.map((value, index) => {
								return (
									<div className="list-container" key={index}>
										<ol className="list-img">
											<img src={value.img} alt="" />
										</ol>
										<ol className="list-text-box">
											<li className="list-title">
												<span>{value.title}</span>
											</li>
											<li className="list-description">
												<span>{value.description}</span>
											</li>
										</ol>
									</div>
								);
							})}
						</li>
					</ul>
				</div>
				<BottomBanner />
			</div>
		</>
	);
}
export default Home;
