import { IHomeBusinessData, IHomeBottomData } from "Components/Module/API/Interface/Data.interface";
import Bottom0 from "Assets/Images/Home/bottom-0.png";
import Bottom1 from "Assets/Images/Home/bottom-1.png";
import Bottom2 from "Assets/Images/Home/bottom-2.png";

export const HomeBusinessDataBase: IHomeBusinessData[] = [
	{
		id: "business-data-0",
		title: "주택시공",
		description:
			"살으리는 설계부터 시공까지 하청업체를 두지 않고 직영공사로 이루어집니다. 디테일한 건축과 합리적인 시공비용으로 꿈에 그린 전원 주택의 낭만을 실현시켜 드립니다."
	},
	{
		id: "business-data-1",
		title: "토지개발, 분양",
		description:
			"살으리는 토지개발부터 시행, 분양, 상담, 마케팅 등 다양한 업무 지원을 하며 고객님의 취지에 맞는 적합한 토지를 선택하도록 컨설팅합니다."
	},
	{
		id: "business-data-2",
		title: "상업공간시공",
		description:
			"살으리는 브랜드 특징에 맞춰 달라지는 내외부 디자인으로 미학과 목적성을 갖춘 만족스러운 결과를 도출해냅니다."
	},
	{
		id: "business-data-3",
		title: "리모델링",
		description:
			"살으리는 전체적인 공간의 톤앤 매너에 맞춰 군더더기 없는 깔끔함과 고급스러운 분위기를 연출함으로써 라이프 스타일에 맞춘 세련된 공간으로 재탄생 시킵니다."
	},
	{
		id: "business-data-4",
		title: "신축공사",
		description:
			"살으리는 투명한 견적 시스템 처음부터 끝까지 공유되는 정직한 작업으로 고객님이 원하는 건축물을 완벽하게 제공합니다."
	},
	{
		id: "business-data-5",
		title: "욕실, 화장실, 주방인테리어",
		description:
			"살으리는 동선을 고려하여 공간을 효율적으로 활용하고 포인트가 될 만한 부분을 고려해 스타일링 합니다. 디자인과 실용을 놓치지 않는 센스 있는 인테리어를 계획합니다."
	}
];

export const HomeBottomDataBase: IHomeBottomData[] = [
	{
		id: "bottom-data-0",
		img: Bottom0,
		title: "Architecture",
		description:
			"살으리는\n\n유행만 따르는 건축이 아닌\n 10년, 20년이 지나도\n\n한결같이 이로운 가치를 지향하고\n온전하게 담아내는 공간을 구축합니다."
	},
	{
		id: "bottom-data-1",
		img: Bottom1,
		title: "Interior",
		description:
			"살으리는\n\n더 나은 공간으로 탈바꿈 시킴으로써\n고객에게 만족할 만한 경험을 제공합니다.\n\n내가 원하는 모습, 나에게 맞춰진 내부,\n새로움을 주는 변화된 스타일로\n공간의 변화를 줍니다."
	},
	{
		id: "bottom-data-2",
		img: Bottom2,
		title: "Branding",
		description:
			"살으리는\n\n공간과 사용자를 기능적으로 이어주기 위한\n디자인과 아이덴티티를 발굴합니다.\n\n고객의 니즈를 적극 수용하여\n살으리의 제시점과 통합된 공간을 브랜딩 합니다."
	}
];
