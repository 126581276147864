import React from "react";
import { IProjectData } from "Components/Module/API/Interface/Data.interface";
import "./DataList.scss";

interface IDataList {
	list: IProjectData[];
	selectTag: string;
	onClickDialogOpen(data: IProjectData): void;
}

function DataList(props: IDataList) {
	const { list, selectTag, onClickDialogOpen } = props;
	return (
		<div className="data-list-container">
			<div className="data-list-wrapper">
				<div className="list">
					{list.map((value, index) => {
						return (
							<>
								{value.tag === (selectTag === "ALL" ? value.tag : selectTag) && (
									<div
										key={value.id + "-" + index}
										className="item-container"
										onClick={() => {
											onClickDialogOpen(value);
										}}
									>
										<div className="item-wrapper">
											<div className="img-box">
												<img src={value.thumbnail} alt="" />
											</div>
											<div className="top-text-box">
												<div className="title">
													<span>{value.title}</span>
												</div>
												<div className="date">
													<span>{value.date}</span>
												</div>
											</div>
											<div className="bottom-text-box">
												<span className="address">
													<span>{value.address}</span>
												</span>
											</div>
										</div>
									</div>
								)}
							</>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default DataList;
