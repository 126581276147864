import NomadMainBanner1 from "Assets/Images/Project/NomadCafe/nomad-main-banner-1.png";
import NomadMainBanner2 from "Assets/Images/Project/NomadCafe/nomad-main-banner-2.png";
import NomadMainBanner3 from "Assets/Images/Project/NomadCafe/nomad-main-banner-3.png";
import NomadMainBanner4 from "Assets/Images/Project/NomadCafe/nomad-main-banner-4.png";
import NomadThumbnail from "Assets/Images/Project/NomadCafe/nomad-thumbnail.png";
import NomadExterior1 from "Assets/Images/Project/NomadCafe/Exterior/nomad-exterior-1.png";
import NomadInterior1 from "Assets/Images/Project/NomadCafe/Interior/nomad-interior-1.png";
import NomadInterior2 from "Assets/Images/Project/NomadCafe/Interior/nomad-interior-2.png";
import NomadInterior3 from "Assets/Images/Project/NomadCafe/Interior/nomad-interior-3.png";
import NomadInterior4 from "Assets/Images/Project/NomadCafe/Interior/nomad-interior-4.png";
import NomadInterior5 from "Assets/Images/Project/NomadCafe/Interior/nomad-interior-5.png";
import NomadInterior6 from "Assets/Images/Project/NomadCafe/Interior/nomad-interior-6.png";
import NomadInterior7 from "Assets/Images/Project/NomadCafe/Interior/nomad-interior-7.png";
import NomadTd1 from "Assets/Images/Project/NomadCafe/ThreeDimensional/nomad-td-1.png";
import NomadTd2 from "Assets/Images/Project/NomadCafe/ThreeDimensional/nomad-td-2.png";
import NomadTd3 from "Assets/Images/Project/NomadCafe/ThreeDimensional/nomad-td-3.png";
import NomadTd4 from "Assets/Images/Project/NomadCafe/ThreeDimensional/nomad-td-4.png";
import NomadTd5 from "Assets/Images/Project/NomadCafe/ThreeDimensional/nomad-td-5.png";
import NomadTd6 from "Assets/Images/Project/NomadCafe/ThreeDimensional/nomad-td-6.png";
import NomadTd7 from "Assets/Images/Project/NomadCafe/ThreeDimensional/nomad-td-7.png";
import NomadTd8 from "Assets/Images/Project/NomadCafe/ThreeDimensional/nomad-td-8.png";
import NomadTd9 from "Assets/Images/Project/NomadCafe/ThreeDimensional/nomad-td-9.png";
import NomadFloorPlan1 from "Assets/Images/Project/NomadCafe/FloorPlan/nomad-floor-plan-1.png";
import NomadFloorPlan2 from "Assets/Images/Project/NomadCafe/FloorPlan/nomad-floor-plan-2.png";

import PiggyBankThumbnail from "Assets/Images/Project/PiggyBank/piggy-bank-thumbnail.png";
import PiggyBankExterior1 from "Assets/Images/Project/PiggyBank/Exterior/piggy-bank-exterior-1.png";
import PiggyBankExterior2 from "Assets/Images/Project/PiggyBank/Exterior/piggy-bank-exterior-2.png";
import PiggyBankExterior3 from "Assets/Images/Project/PiggyBank/Exterior/piggy-bank-exterior-3.png";
import PiggyBankExterior4 from "Assets/Images/Project/PiggyBank/Exterior/piggy-bank-exterior-4.png";
import PiggyBankExterior5 from "Assets/Images/Project/PiggyBank/Exterior/piggy-bank-exterior-5.png";
import PiggyBankInterior1 from "Assets/Images/Project/PiggyBank/Interior/piggy-bank-interior-1.png";
import PiggyBankInterior2 from "Assets/Images/Project/PiggyBank/Interior/piggy-bank-interior-2.png";
import PiggyBankInterior3 from "Assets/Images/Project/PiggyBank/Interior/piggy-bank-interior-3.png";
import PiggyBankInterior4 from "Assets/Images/Project/PiggyBank/Interior/piggy-bank-interior-4.png";
import PiggyBankInterior5 from "Assets/Images/Project/PiggyBank/Interior/piggy-bank-interior-5.png";
import PiggyBankInterior6 from "Assets/Images/Project/PiggyBank/Interior/piggy-bank-interior-6.png";

import ShinSaOfficeMainBanner1 from "Assets/Images/Project/ShinSaOffice/shinsa-office-main-banner-1.png";
import ShinSaOfficeMainBanner2 from "Assets/Images/Project/ShinSaOffice/shinsa-office-main-banner-2.png";
import ShinSaOfficeMainBanner3 from "Assets/Images/Project/ShinSaOffice/shinsa-office-main-banner-3.png";
import ShinSaOfficeThumbnail from "Assets/Images/Project/ShinSaOffice/shinsa-office-thumbnail.png";
import ShinSaOfficeInterior1 from "Assets/Images/Project/ShinSaOffice/Interior/shinsa-office-interior-1.png";
import ShinSaOfficeInterior2 from "Assets/Images/Project/ShinSaOffice/Interior/shinsa-office-interior-2.png";
import ShinSaOfficeInterior3 from "Assets/Images/Project/ShinSaOffice/Interior/shinsa-office-interior-3.png";
import ShinSaOfficeInterior4 from "Assets/Images/Project/ShinSaOffice/Interior/shinsa-office-interior-4.png";
import { IProjectData } from "Components/Module/API/Interface/Data.interface";

export const ProjectDataBase: IProjectData[] = [
	{
		id: "NomadCafe",
		tag: "CAFE",
		mainBanner: [NomadMainBanner1, NomadMainBanner2, NomadMainBanner3, NomadMainBanner3],
		thumbnail: NomadThumbnail,
		title: "경기도 광주 대형카페 / 200평",
		description: "200평 대형카페 주방, 공간 인테리어/익스테리어 진행",
		date: "2022",
		address: "Gwangju-si, Gyeonggi-do, Republic of Korea",
		link: "",
		exteriorImg: [NomadExterior1],
		interiorImg: [
			NomadInterior1,
			NomadInterior2,
			NomadInterior3,
			NomadInterior4,
			NomadInterior5,
			NomadInterior6,
			NomadInterior7
		],
		threeDimensionalImg: [
			NomadTd1,
			NomadTd2,
			NomadTd3,
			NomadTd4,
			NomadTd5,
			NomadTd6,
			NomadTd7,
			NomadTd8,
			NomadTd9
		],
		floorPlanImg: [NomadFloorPlan1, NomadFloorPlan2]
	},
	{
		id: "PiggyBank",
		tag: "RESTAURANT",
		mainBanner: [""],
		thumbnail: PiggyBankThumbnail,
		title: "신사역 고기전문점 / 30평",
		description: "",
		date: "2021",
		address: "Gangnam-gu, Seoul, Republic of Korea",
		link: "",
		exteriorImg: [
			PiggyBankExterior1,
			PiggyBankExterior2,
			PiggyBankExterior3,
			PiggyBankExterior4,
			PiggyBankExterior5
		],
		interiorImg: [
			PiggyBankInterior1,
			PiggyBankInterior2,
			PiggyBankInterior3,
			PiggyBankInterior4,
			PiggyBankInterior5,
			PiggyBankInterior6
		],
		threeDimensionalImg: [],
		floorPlanImg: []
	},
	{
		id: "ShinSaOffice",
		tag: "OFFICE",
		mainBanner: [ShinSaOfficeMainBanner1, ShinSaOfficeMainBanner2, ShinSaOfficeMainBanner3],
		thumbnail: ShinSaOfficeThumbnail,
		title: "신사역 광고 에이전시 사무실 / 100평",
		description: "100평 사무실 / 시사실 인테리어 진행",
		date: "2019",
		address: "Gangnam-gu, Seoul, Republic of Korea",
		link: "",
		exteriorImg: [],
		interiorImg: [
			ShinSaOfficeInterior1,
			ShinSaOfficeInterior2,
			ShinSaOfficeInterior3,
			ShinSaOfficeInterior4
		],
		threeDimensionalImg: [],
		floorPlanImg: []
	}
];
