import React from "react";
import { ILineMenuData } from "Components/Module/API/Interface/Common.interface";
import { listenerCount } from "process";
import "./LineMenu.scss";

interface ILineMenu {
	list: ILineMenuData[];
	selectTag: string;
	onChangeInput(value: string): void;
}
const LineMenu = (props: ILineMenu) => {
	const { list, selectTag, onChangeInput } = props;
	return (
		<div className="menu-container">
			<div className="menu-wrapper">
				{list.map((value, index) => {
					return (
						<div key={value.id + "-" + index} className="radio-btn">
							<input
								id={value.id}
								type="radio"
								name="menu"
								value={value.value}
								checked={selectTag === value.value}
								className="menu-item"
								onChange={() => {
									onChangeInput(value.value);
								}}
							/>
							<label htmlFor={value.id} className="menu-label">
								{value.title}
							</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default LineMenu;
