import React from "react";
import Banner from "Assets/Images/About/banner.png";
import "./About.scss";
import SubLine from "Components/Common/SubLine/SubLine";
import {
	AboutDirectionalDataBase,
	AboutPhilosophyDataBase
} from "Components/Data/About/AboutDataBase";
import BottomBanner from "Components/Common/BottomBanner/BottomBanner";

function About() {
	return (
		<div className="about-container">
			<div className="about-wrapper">
				<ul className="about-introduce-box">
					<li className="introduce-title">
						<span>ㅅㅇㄹ</span>
					</li>
					<li className="introduce-description">
						<span>
							“유익한 삶을 살 수 있는 공간에서 살 수 있도록”
							<br />
							<br />
							삶의 질이 보장되는 꿈의 공간을 만들고 현재의 모든 순간을 만끽하고
							싶습니까?
							<br />
							<br />
							당신의 니즈를 이해하고 충족하고 싶은 브랜드 살으리는 이것에서부터
							시작되었습니다
							<br />
							<br />단 하나의 주인인 당신만을 위한 파라다이스를 만들어 드리는
							살으리입니다.
						</span>
					</li>
				</ul>
				<ul className="about-introduce-banner">
					<li className="introduce-banner">
						<img src={Banner} alt="" />
					</li>
				</ul>
				<ul className="about-philosophy-container">
					<SubLine title="ㅅㅇㄹ PHILOSOPHY" />
					<ol className="about-philosophy-wrapper">
						{AboutPhilosophyDataBase.map((value, index) => {
							return (
								<div key={index} className="list">
									<div className="list-title">
										<span>{value.title}</span>
									</div>
									<div className="list-description">
										<span>{value.description}</span>
									</div>
								</div>
							);
						})}
					</ol>
				</ul>
				<ul className="about-directional-container">
					<SubLine title="ㅅㅇㄹ DIRECTIONAL" />
					<ol className="about-directional-wrapper">
						<li className="title">
							<span>살으리의 방향성</span>
						</li>
						<li className="list-container">
							{AboutDirectionalDataBase.map((value, index) => {
								return (
									<div key={index} className="list-wrapper">
										<div className="list-title">{value.title}</div>
										<div className="list-description">{value.description}</div>
									</div>
								);
							})}
						</li>
					</ol>
				</ul>
			</div>
			<BottomBanner />
		</div>
	);
}

export default About;
