import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.scss";

import LogoImg from "Assets/Images/Common/logo-icon.svg";
import { RoutesString } from "Components/Module/API/RoutesString";
function Header() {
	return (
		<>
			<div className="header-container">
				<div className="header-wrapper">
					<Link to={RoutesString.Home}>
						<img className="logo-img" src={LogoImg} alt="" />
					</Link>

					<div className="menu">
						<NavLink className="en" to={RoutesString.Home}>
							HOME
						</NavLink>
						<NavLink className="kr" to={RoutesString.About}>
							ㅅㅇㄹ
						</NavLink>
						<NavLink className="en" to={RoutesString.Consulting}>
							CONSULTING
						</NavLink>
						<NavLink className="en" to={RoutesString.Project}>
							PROJECT
						</NavLink>
						<NavLink className="en" to={RoutesString.Contact}>
							CONTACT
						</NavLink>
					</div>
				</div>
			</div>
		</>
	);
}
export default Header;
