import { ILineMenuData } from "Components/Module/API/Interface/Common.interface";

export const ConsultingMenuData: ILineMenuData[] = [
	{
		id: "all",
		title: "ALL",
		value: "ALL"
	},
	{
		id: "office",
		title: "OFFICE",
		value: "OFFICE"
	},
	{
		id: "cafe",
		title: "CAFE",
		value: "CAFE"
	},
	{
		id: "restaurant",
		title: "RESTAURANT",
		value: "RESTAURANT"
	}
];
