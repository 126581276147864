import React, { useEffect, useState } from "react";
import DataList from "Components/Common/DataList/DataList";
import LineMenu from "Components/Common/LineMenu/LineMenu";
import { ProjectMenuData } from "Components/Data/Project/ProejctMenuData";
import "./Project.scss";
import { ProjectDataBase } from "Components/Data/Project/ProjectDataBase";
import DataDialog from "Components/Common/DataDialog/DataDialog";
import { IProjectData } from "Components/Module/API/Interface/Data.interface";

function Project() {
	const [selectTag, setSelectTag] = useState<string>("ALL");
	const [dialogRender, setDialogRender] = useState<boolean>(false);
	const [selectData, setSelectData] = useState<IProjectData>({
		id: "",
		tag: "",
		mainBanner: [""],
		thumbnail: "",
		title: "",
		description: "",
		date: "",
		address: "",
		link: "",
		exteriorImg: [],
		interiorImg: [],
		threeDimensionalImg: [],
		floorPlanImg: []
	});

	useEffect(() => {
		console.log(selectData);
	}, [selectData]);

	const handleClickDialogOpen = (data: IProjectData) => {
		setSelectData(data);
		setDialogRender(true);
	};
	const handleClickDialogClose = () => {
		setDialogRender(false);
	};
	const inputChange = (value: string) => {
		setSelectTag(value);
	};

	useEffect(() => {
		if (dialogRender === true) {
			document.body.style.cssText = `
			position: fixed; 
			top: -${window.scrollY}px;
			overflow-y: scroll;
			width: 100%;`;
			return () => {
				const scrollY = document.body.style.top;
				document.body.style.cssText = "";
				window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
			};
		}
	}, [dialogRender]);

	return (
		<div className="project-container">
			<div className="project-wrapper">
				{dialogRender === true && (
					<DataDialog data={selectData} onClickCloseDialog={handleClickDialogClose} />
				)}
				<div className="project-intro-box">
					<li className="left-box">
						<span className="title">PROJECT</span>
					</li>
					<li className="right-box">
						<span className="description">
							살으리의 내부 프로젝트는 고객이 우리의 디자인과
							<br />
							인테리어 스타일에 대해 더 많이 이해하는 데 도움이 될 것입니다.
							<br />
							또한 고객은 사용 가능한 여러 디자인을 참조하여 색상과 디자인
							<br />
							스타일을 더 쉽게 선택할 수 있습니다.
						</span>
					</li>
				</div>
				<div className="project-menu">
					<LineMenu
						list={ProjectMenuData}
						selectTag={selectTag}
						onChangeInput={inputChange}
					/>
				</div>
				<div className="project-data-list">
					<DataList
						list={ProjectDataBase}
						selectTag={selectTag}
						onClickDialogOpen={handleClickDialogOpen}
					/>
				</div>
			</div>
		</div>
	);
}

export default Project;
