import React, { useEffect } from "react";
declare global {
	interface Window {
		kakao: any;
	}
}

const KakaoMap: React.FC = () => {
	const { kakao } = window;
	useEffect(() => {
		const container = document.getElementById("myMap");
		const options = {
			center: new kakao.maps.LatLng(35.12, 129.1),
			level: 3
		};
		// 지도를 생성합니다.
		const map = new kakao.maps.Map(container, options);
		// 주소-좌표 변환 객체를 생성합니다.
		const geocoder = new kakao.maps.services.Geocoder();
		// 주소로 좌표를 검색합니다..
		geocoder.addressSearch(
			"경기도 양평군 강하면 강남로 198",
			function (result: any, status: any) {
				// 정상적으로 검색이 완료됐으면
				if (status === kakao.maps.services.Status.OK) {
					const coords = new kakao.maps.LatLng(result[0].y, result[0].x);

					// 결과값으로 받은 위치를 마커로 표시합니다
					const marker = new kakao.maps.Marker({
						map: map,
						position: coords
					});

					// 인포윈도우로 장소에 대한 설명을 표시합니다
					const infowindow = new kakao.maps.InfoWindow({
						content:
							'<div style="font-family: Noto Sans KR;width:150px;text-align:center;padding:6px 0;">살으리 3F</div>'
					});
					infowindow.open(map, marker);

					// 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
					map.setCenter(coords);
				}
			}
		);
	}, []);

	return (
		<div
			id="myMap"
			style={{
				width: "100%",
				height: "100%"
			}}
		></div>
	);
};

export default KakaoMap;
