import React from "react";
import "./BottomBanner.scss";

import { Link } from "react-router-dom";
import ArrowIcon from "Assets/Images/Common/arrow-icon.png";
import { RoutesString } from "Components/Module/API/RoutesString";

function BottomBanner() {
	return (
		<div className="bottom-banner-container">
			<ul className="bottom-banner-wrapper">
				<li className="bottom-banner">
					<div className="bottom-banner-box">
						<div className="banner-title">
							<span>New start, Now With Saluli</span>
						</div>
						<div className="banner-description">
							<span>Start successful consulting</span>
						</div>
						<div className="banner-button">
							<Link to={RoutesString.Contact}>
								<div className="banner-button-img">
									<img src={ArrowIcon} alt="Icon"></img>
								</div>
								<div className="banner-button-text">
									<span>CONTACT</span>
								</div>
							</Link>
						</div>
					</div>
				</li>
			</ul>
		</div>
	);
}

export default BottomBanner;
