import React from "react";
import "./Footer.scss";
import Logo from "Assets/Images/Common/logo-icon.svg";
import { Link, NavLink } from "react-router-dom";
import { RoutesString } from "Components/Module/API/RoutesString";
function Footer() {
	return (
		<div className="footer-container">
			<div className="footer-wrapper">
				<ul className="footer-left-box">
					<ol className="top-box">
						<li className="logo-img">
							<img src={Logo} alt="" />
						</li>
						<li className="sns-links">
							<Link to="/">
								<span>Facebook</span>
							</Link>
							<Link to="/">
								<span>Youtube</span>
							</Link>
							<Link to="/">
								<span>Instagram</span>
							</Link>
						</li>
					</ol>
					<ol className="bottom-box">
						<span>2022 ⓒ All rights reserved</span>
					</ol>
				</ul>
				<ul className="footer-center-box">
					<li className="top-box">
						<span>
							T. 070. 4242. 9228
							<br />
							E. saluli.hello@gmail.com
						</span>
					</li>
					<li className="bottom-box">
						<span className="address-kr">
							주식회사 살으리
							<br />
							경기도 양평군 강하면 강남로 198 강하면 3층
							<br />
						</span>
						<span className="address-en">
							3F 198, Gangnam-ro, Gangha-myeon, Yangpyeong-gun, Gyeonggi-do, Republic
							of Korea
						</span>
					</li>
				</ul>
				<ul className="footer-right-box">
					<ol className="links">
						<li className="link">
							<NavLink to={RoutesString.Home}>
								<span className="en">HOME</span>
							</NavLink>
						</li>
						<li className="link">
							<NavLink to={RoutesString.About}>
								<span className="kr">ㅅㅇㄹ</span>
							</NavLink>
						</li>
						<li className="link">
							<NavLink to={RoutesString.Consulting}>
								<span className="en">CONSULTING</span>
							</NavLink>
						</li>
						<li className="link">
							<NavLink to={RoutesString.Project}>
								<span className="en">PROJECT</span>
							</NavLink>
						</li>
						<li className="link">
							<NavLink to={RoutesString.Contact}>
								<span className="en">CONTACT</span>
							</NavLink>
						</li>
					</ol>
				</ul>
			</div>
		</div>
	);
}

export default Footer;
