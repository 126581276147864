import React, { useEffect } from "react";
import KakaoMap from "Components/Common/KakaoMap/KakaoMap";
import "./Contact.scss";
function Contact() {
	return (
		<div className="contact-container">
			<div className="contact-wrapper">
				<ul className="contact-top-box">
					<li className="contact-title">
						<span>CONTACT</span>
					</li>
					<li className="contact-description">
						<span>
							주변의 생활공간 및 업무공간과 마찬가지로 살으리는 멀리 있지 않고
							<br />
							필요할 때 항상 당신을 위해 있습니다.
						</span>
					</li>
				</ul>
				<ul className="contact-bottom-box">
					<li className="contact-map-box">
						<KakaoMap />
					</li>
					<li className="contact-text-box">
						<div className="info-container">
							<div className="info-wrapper">
								<div className="title">
									<span>Company</span>
								</div>
								<a className="email" href="mailto:support@maxius.io" title="">
									<span>saluli.hello@gmail.com</span>
								</a>
								<div className="title">
									<span>Technical support</span>
								</div>
								<a className="email" href="mailto:support@maxius.io" title="">
									<span>saluli.hello@gmail.com</span>
								</a>
								<div className="title">PhoneNumber</div>
								<div className="phone-number">
									<span>Tel 070. 4242. 9228</span>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Contact;
